// eslint-disable-next-line settle/preferred-modules
import { Link as MuiLink } from '@mui/material'
import { styled } from '@mui/material/styles'
import { youShallNotPass } from 'lib/helpers/youShallNotPass/youShallNotPass'

export const Link = styled(MuiLink, {
  shouldForwardProp: youShallNotPass('disabled'),
})<{ disabled: boolean }>(({ theme, disabled }) => ({
  ...(disabled && {
    color: theme.tokens.ThemeColorCommonOnSurfaceTertiary,
    pointerEvents: 'none',
    touchAction: 'none',
  }),
}))

export const ProgressContainer = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: theme.spacing(1),
}))
