import { forwardRef } from 'react'
import { toString } from 'lodash'
import { CircularProgress, type LinkProps } from '@mui/material'
import type { LinkProps as NextLinkProps } from 'next/link'
import * as $ from './Link.styled'

export type TLinkProps = Omit<LinkProps, 'href'> &
  Omit<NextLinkProps, 'href'> & {
    href?: NextLinkProps['href']
    disabled?: boolean
    loading?: boolean
  }

export const Link = forwardRef<HTMLAnchorElement, TLinkProps>(
  ({ href, children, disabled = false, loading = false, color = 'secondary', ...props }, ref) => (
    <$.Link
      ref={ref}
      // @ts-expect-error we need to find a way to merge nextjs and mui link types
      href={href}
      aria-disabled={toString(disabled)}
      disabled={disabled || loading}
      color={color}
      {...props}
    >
      {children}
      {loading && (
        <$.ProgressContainer>
          <CircularProgress color="inherit" size={12} />
        </$.ProgressContainer>
      )}
    </$.Link>
  ),
)

Link.displayName = 'Link'
