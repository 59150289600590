import { styled } from '@mui/material/styles'
import { Link as SuiLink } from 'settle-ui/components/Link/Link'

export const Root = styled('footer')(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

export const Links = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: '0.5',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}))

export const Link = styled(SuiLink)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  color: theme.tokens.ThemeColorCommonOnSurfaceTertiary,
}))

export const Text = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  color: theme.tokens.ThemeColorCommonOnSurfaceTertiary,
  fontSize: '14px',
}))
